import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "Log",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '请求类型',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: 'GET'
        }, {
          id: '2',
          name: 'POST'
        }, {
          id: '3',
          name: 'PUT'
        }, {
          id: '4',
          name: 'DELETE'
        }]
      }, {
        placeholder: '请求结果',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '成功'
        }, {
          id: '0',
          name: '失败'
        }]
      }, {
        placeholder: '客户端类型',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '管理端'
        }, {
          id: '2',
          name: 'Web电脑端'
        }, {
          id: '3',
          name: 'Web移动端'
        }]
      }, {
        placeholder: '日志类型',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '登录'
        }, {
          id: '2',
          name: '登出'
        }, {
          id: '3',
          name: '列表'
        }, {
          id: '4',
          name: '添加'
        }, {
          id: '5',
          name: '修改'
        }, {
          id: '6',
          name: '删除'
        }, {
          id: '7',
          name: '清除'
        }, {
          id: '8',
          name: '启用'
        }, {
          id: '9',
          name: '禁用'
        }, {
          id: '10',
          name: '搜索'
        }, {
          id: '11',
          name: '上传'
        }, {
          id: '12',
          name: '导出'
        }]
      }, {
        placeholder: '',
        value: '',
        type: 'data',
        list: []
      }, {
        placeholder: '操作名称',
        value: '',
        type: 'text',
        list: []
      }, {
        placeholder: '手机号',
        value: '',
        type: 'text',
        list: []
      }],
      postListData: {
        requestType: '',
        responseResult: '',
        clientType: '',
        logType: '',
        startDate: '',
        endDate: '',
        name: '',
        pageSize: '',
        pageIndex: '',
        mobile: ''
      },
      // 用户信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: 'rtl',
      drawerState: '',
      drawerData: {
        name: "",
        //操作名称
        module: "",
        //操作模块
        requestType: "",
        //请求类型
        requestUrl: "",
        //请求Url
        requestParam: "",
        //请求参数
        responseState: "",
        //请求结果
        duration: "",
        //耗时
        ip: "",
        //IP地址
        ipInfo: "",
        //IP信息
        deviceInfo: "",
        //设备信息
        clientType: "",
        //客户端
        logType: "",
        //日志类型
        userId: "",
        //用户Id
        username: "",
        //用户名
        createdAt: "" //操作时间
      },

      // dialog弹框-删除弹框
      delId: '',
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  components: {},
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: 'getLogList',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.tableData = res.data.data;
            _this.total = res.data.total;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === 'edit') {
        this.drawerState = 'edit';
        this.drawer = true;
        this.drawerData = {
          name: val.name,
          type: val.type,
          rank: val.rank,
          state: val.state,
          id: val.id
        };
      } else if (type === 'del') {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs['form'].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = '';
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === 'edit') {
            //修改
            url = 'editLog';
          } else {
            //添加
            url = 'addLog';
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  name: '',
                  type: '',
                  rank: '',
                  state: ''
                };
                if (that.drawerState === 'add') {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs['form'].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this2 = this;
      //删除当前的日志
      this.service.axiosUtils({
        requestName: 'deleteLog',
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success(res.message);
            _this2.delId = '';
            _this2.centerDialogVisible = false;
            _this2.initData();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.requestType = filterData[0].value;
        this.postListData.responseResult = filterData[1].value;
        this.postListData.clientType = filterData[2].value;
        this.postListData.logType = filterData[3].value;
        this.postListData.startDate = filterData[4].value[0];
        this.postListData.endDate = filterData[4].value[1];
        this.postListData.name = filterData[5].value;
        this.postListData.mobile = filterData[6].value;
      } else {
        this.postListData.requestType = '';
        this.postListData.responseResult = '';
        this.postListData.clientType = '';
        this.postListData.logType = '';
        this.postListData.startDate = '';
        this.postListData.endDate = '';
        this.postListData.name = '';
        this.postListData.mobile = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.requestType = '';
      this.postListData.responseResult = '';
      this.postListData.clientType = '';
      this.postListData.logType = '';
      this.postListData.startDate = '';
      this.postListData.endDate = '';
      this.postListData.name = '';
      this.postListData.mobile = '';
      this.currentPage = 1;
      this.initData();
    }
  }
};